.BasePage {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.Header {
  color: red;
  font-size: 30px;
  text-decoration: underline;
  margin-bottom: 30px;
}
.questionParagraph {
  margin-top: 30px;
}

.ContainerText {
  text-align: center;
  color: #606060;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 43px;
}
.ButtonBox {
  width: 100%;
  display: flex;
  justify-content: center;
}
.ButtonSpacer {
  width: 10%;
}
.ButtonNo {
  font-family: 'Poppins', sans-serif;
}
.ButtonYes {
  font-family: 'Poppins', sans-serif;
}

.Footer {
  margin: auto auto 0 auto;
}
.Footer .IonImg {
  width: 4.5em;
  display: flex;
  margin-top: 2em;
}
