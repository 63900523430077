body {
  font-family: "Open Sans", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.toast-dlg75pct {
  --width: 75%;
}

ion-router-outlet {
  width: 430px;
  padding: 7% 2rem 1rem 2rem;
  margin: 0 auto 2rem auto;
  height: 100%;
}

@media only screen and (max-width: 768px) {
  ion-router-outlet{
    padding-top: 4rem;
    padding-left: 2rem;
    padding-right: 2rem;
    width: 100%;
    height: 100%;
    overflow: scroll;
  }
}

/**
* CSS for components\layout\Alert.js - Could not make module CSS work...
*/
.AlertCancelButton, .AlertAllowButton, 
#IdgoAlert .alert-wrapper .alert-head h2, 
#IdgoAlert .alert-message {
  color: #033364 !important;
  padding-top: 10px;
}

.fido-alert-1 > .alert-wrapper > .alert-message {
  font-size: 14px;
  color: #666666;
  line-height: 1.5;
  padding-top: 10px;
}